import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "action"
    }}>{`Action`}</h1>
    <p>{`Sigi `}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` was implemented by `}<inlineCode parentName="p">{`State`}</inlineCode>{` object under the hood. `}<inlineCode parentName="p">{`State`}</inlineCode>{` object is very similar with redux `}<inlineCode parentName="p">{`Store`}</inlineCode>{` object. It `}<strong parentName="p">{`Dispatch`}</strong>{` actions to `}<inlineCode parentName="p">{`reducers`}</inlineCode>{` and `}<inlineCode parentName="p">{`effects`}</inlineCode>{`, and receive actions from `}<inlineCode parentName="p">{`Dispatcher`}</inlineCode>{` and `}<inlineCode parentName="p">{`Effect`}</inlineCode>{`.`}</p>
    <p>{`In sigi application, you will never need to create an `}<inlineCode parentName="p">{`Action`}</inlineCode>{` by hand, so we do not expose the `}<inlineCode parentName="p">{`Dispatch`}</inlineCode>{` function.
In replacement, there are two ways to dispatch action:`}</p>
    <ul>
      <li parentName="ul">{`call methods on `}<strong parentName="li">{`Dispatcher`}</strong></li>
      <li parentName="ul">{`emit actions in `}<strong parentName="li">{`Effect`}</strong></li>
    </ul>
    <h2 {...{
      "id": "dispatcher--actionscreator"
    }}>{`Dispatcher & ActionsCreator`}</h2>
    <p>{`Dispatcher is a high level abstraction for `}<strong parentName="p">{`ActionsCreator`}</strong>{` with well defined type information.`}</p>
    <p>{`Every method in `}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` class decorated by `}<strong parentName="p">{`Reducer`}</strong>{`, `}<strong parentName="p">{`ImmerReducer`}</strong>{`, `}<strong parentName="p">{`Effect`}</strong>{` , `}<strong parentName="p">{`DefineAction`}</strong>{` `}<inlineCode parentName="p">{`Decorators`}</inlineCode>{` match one property with the same name in `}<strong parentName="p">{`ActionsCreator`}</strong>{` in `}<inlineCode parentName="p">{`EffectModule class`}</inlineCode>{`, and match one property with the same name in `}<strong parentName="p">{`Dispatcher`}</strong>{` in `}<inlineCode parentName="p">{`Component`}</inlineCode>{`. The `}<strong parentName="p">{`ActionsCreator`}</strong>{` in `}<inlineCode parentName="p">{`EffectModule class`}</inlineCode>{` is used for emit action in `}<strong parentName="p">{`Effect`}</strong>{`, and the `}<strong parentName="p">{`Dispatcher`}</strong>{` in `}<inlineCode parentName="p">{`Component`}</inlineCode>{` is used for `}<inlineCode parentName="p">{`Dispatch`}</inlineCode>{` actions to `}<inlineCode parentName="p">{`State`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "dispatch-props-created-by-redux-actions"
    }}>{`Dispatch props created by Redux-Actions`}</h3>
    <p>{`You may have used `}<a parentName="p" {...{
        "href": "https://github.com/redux-utilities/redux-actions"
      }}>{`Redux-Actions`}</a>{` in `}<strong parentName="p">{`Redux`}</strong>{` application. `}<strong parentName="p">{`ActionsCreator`}</strong>{` in sigi is very similar with `}<strong parentName="p">{`ActionsCreator`}</strong>{` in `}<inlineCode parentName="p">{`Redux-Actions`}</inlineCode>{`.And there are few advantages for create `}<strong parentName="p">{`ActionsCreator`}</strong>{` using `}<inlineCode parentName="p">{`Redux-Actions`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul">{`Avoid `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Magic_number_(programming)"
        }}>{`Magic string`}</a></li>
      <li parentName="ul">{`Provide type information`}</li>
      <li parentName="ul">{`Reduce boilerplate codes`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`// raw dispatcher
connect(mapStateToProps, (dispatch) => bindActionCreators({
  addCount: (count: number) => dispatch({ // losing type information here
    type: 'ADD_COUNT',
    payload: count,
  }),
}, dispatch))

// reducer
export const reducer = (state, action) => { // losing type information here
  switch action.type:
    case: 'ADD_COUNT':
      return { ...state, count: state.count + payload }
    default:
      return { count: 0 }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const ADD_COUNT = createAction<number>("ADD_COUNT");

interface DispatchProps {
  addOne: typeof ADD_COUNT;
}

interface StateProps {
  count: number;
}

// react actions dispatcher
connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      addCount: ADD_COUNT,
    } as DispatchProps,
    dispatch
  )
);

// reducer
export const reducer = handleActions(
  {
    [\`\${ADD_COUNT}\`]: (state: StateProps, { payload }: Action<number>) => {
      return { ...state, count: state.count + payload };
    },
  },
  { count: 0 }
);
`}</code></pre>
    <h3 {...{
      "id": "the-sigi-way"
    }}>{`The Sigi way`}</h3>
    <h4 {...{
      "id": "definition"
    }}>{`Definition`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Module, EffectModule, Reducer } from "@sigi/core";
interface State {
  count: number;
}

@Module("SomeNamespace")
class SomeModule extends EffectModule<State> {
  defaultState = {
    count: 0,
  };

  @Reducer()
  add(state: State, count: number) {
    return { ...state, count: state.count + count };
  }
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`As you can see above, in the definition part of a sigi app, there is no magic action string,
no redundant boilerplate 🤯.`}</p>
    </blockquote>
    <h4 {...{
      "id": "usage"
    }}>{`Usage`}</h4>
    <p>{`For react APP, we provide two hooks that let you refer actions defined in effect modules.
both of them receive a `}<strong parentName="p">{`EffectModule`}</strong>{` class and produce a dispatcher referred to this module.`}</p>
    <h4 {...{
      "id": "usemodule-and-usedispatchers"
    }}><inlineCode parentName="h4">{`useModule`}</inlineCode>{` and `}<inlineCode parentName="h4">{`useDispatchers`}</inlineCode></h4>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`// use module
import { useDispatchers, useModule } from "@sigi/react";

const SomeComponent = () => {
  // note: we don't need to worry about the types of dispatcher.
  // Sigi's type system will ensure all defined actions/effects are 100% accurate referred in dispatcher
  const [state, dispatcher] = useModule(SomeModule);
  // or, if you don't care about the state changes.
  // const dispatcher = useDispatchers(SomeModule);

  const onClickAdd = useCallback(() => {
    // ts will perform typecheck in the payload part
    // and you can also perform **Jump to definition** in code editor
    dispatcher.add(1);
    // ts type error
    // dispatch.add('string value')
  }, [dispatcher]);

  return (
    <div>
      <button onClick={onClickAdd}>add 1</button>
      <span>count: {state.count}</span>
    </div>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      